.ant-tabs-top > .ant-tabs-nav::before,
.ant-tabs-bottom > .ant-tabs-nav::before,
.ant-tabs-top > div > .ant-tabs-nav::before,
.ant-tabs-bottom > div > .ant-tabs-nav::before {
  @apply hidden;
}

.ant-tabs-tab {
  font-size: inherit;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: inherit;
  font-weight: inherit;
}

.ant-tabs-tab.ant-tabs-tab-disabled {
  @apply text-black-op-50;
}

.ant-tabs {
  .ant-tabs-nav-list {
    @apply text-m;
  }

  &.size-large .ant-tabs-nav-list {
    @apply text-l;
  }

  .ant-tabs-tab-disabled {
    @apply text-black-op-50;
    pointer-events: none;
  }

  .ant-tabs-tab:hover {
    color: inherit;
  }

  .ant-tabs-nav {
    @apply text-primary-black;
  }

  &.type-white .ant-tabs-nav {
    @apply text-primary-white;
  }

  &.type-white .ant-tabs-tab-disabled {
    @apply text-white-op-50;
  }

  .ant-tabs-ink-bar {
    background-color: currentColor;
    height: 4px !important;
    border-radius: 2px;
  }

  .ant-tabs-tab-active {
    font-weight: bold;
  }
}

.modal-dialog-height-overflow {
  height: 50vh;
  overflow: auto;
}
