.ant-input-affix-wrapper {
  @apply relative h-10 my-2;

  &.curved {
    @apply z-0 rounded-3xl bg-ice-150 text-base text-gray-600 px-4 py-2;
    input {
      @apply bg-ice-150;
    }
  }

  &.default {
    @apply z-0 rounded-md bg-ice-150 text-base text-gray-600 px-4 py-2 mt-0;
    input {
      @apply bg-ice-150;
    }
  }
}

input,
textarea {
  @apply outline-none;
}

.textarea {
  @apply relative h-10 my-2;

  &.curved {
    @apply rounded-3xl bg-ice-150 text-base text-gray-600 px-4 py-2;
    @apply bg-ice-150;
  }

  &.default {
    @apply rounded-md bg-ice-150 text-base text-gray-600 px-4 py-2 mt-0;
    @apply bg-ice-150;
  }
}

.ant-form-item-control-input {
  min-height: auto;
}
