@import "./vendors/index.css";

@import "tailwindcss/base";
@import "./base/index.css";

@import "tailwindcss/components";
@import "./components/index.css";

@import "tailwindcss/utilities";
@import "./utilites/index.css";
