.ant-picker {
  @apply h-10 text-m bg-ice-150 text-gray-600 px-4 py-2;

  &.default {
    @apply rounded-md;
  }

  &.curved {
    @apply rounded-3xl;
  }

  .ant-drawer .ant-picker-clear {
  }

  .ant-picker-clear {
    @apply bg-ice-150;
  }

  &.ant-picker-disabled {
    @apply opacity-50;
  }
}

.ant-picker-dropdown {
  .ant-picker-ok .ant-btn {
    @apply p-1.5 h-7.5;
    @apply bg-green-20 hover:bg-green-20;
  }
}
