.avatar-dropdown {
  @apply relative;

  .ant-popover {
    @apply pt-0 -right-5;
    @apply -top-2 !important;
    @apply left-auto !important;
    top: calc(-0.5rem - 2px) !important;
  }

  .ant-popover-arrow {
    @apply hidden;
  }

  .ant-popover-inner {
    @apply p-5 rounded-2.5xl shadow-m-10;
  }
}
