.ant-message {
  .anticon {
    @apply hidden;
  }

  .ant-message-notice-content {
    @apply p-0 rounded-2lg bg-black-op-80 backdrop-blur-10;
    max-width: 526px;
  }

  .icon-wrapper {
    @apply px-3 py-6 border-2 border-primary-black rounded-2lg;
  }

  .type-success {
    @apply bg-green-10;
  }

  .type-warning {
    @apply bg-red-10;
  }

  .type-info {
    @apply bg-orchid-10;
  }

  .content {
    @apply flex flex-col text-primary-white px-2.5 py-1.5 font-normal;
  }

  .row {
    @apply flex items-center text-sm flex-1;
  }
}
