.accordion-form-item {
  @apply mb-0 w-64 flex;

  .ant-form-item-control,
  .ant-form-item-control-input,
  .ant-form-item-control-input-content,
  .accordion-form-field {
    @apply h-full;
  }

  .accordion-form-field {
    @apply max-w-full border-none rounded-none mb-0;
    @apply bg-transparent !important;

    input {
      @apply border-none;
      @apply bg-transparent !important;
    }
  }
}

.ant-form-item-label > label:after {
  content: normal;
}
