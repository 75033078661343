.ant-drawer-header {
  @apply p-0 bg-transparent rounded-none border-none text-transparent;
}

.ant-drawer-content {
  @apply bg-transparent;
}

.ant-drawer-mask {
  @apply bg-black-op-20;
}

.ant-drawer-body {
  @apply block p-0 bg-primary-white;
}

.ant-drawer-footer {
  @apply block px-8 py-4 bg-primary-white;
}
