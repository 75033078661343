.ant-radio-group {
  .ant-radio-button-wrapper {
    @apply bg-gray-100 border-0 text-charcoal px-4 py-2 h-auto text-m;

    &:hover {
      color: inherit;
    }

    &:focus-within,
    &:focus {
      @apply shadow-none;
    }

    &:before {
      content: normal;
    }

    .ant-radio-button-checked {
      @apply bg-orchid-10 rounded-3xl;
    }
  }
  .ant-radio-button-wrapper-checked {
    @apply text-black;
  }
}

.ant-radio-group.spiciness {
  .ant-radio-button-wrapper {
    @apply bg-ice-100;

    .ant-radio-button-checked {
      @apply bg-red-10;
    }
  }
}
