.ant-tooltip {
  @apply text-xs;

  .size-medium {
    @apply text-s;
  }

  .size-large {
    @apply text-m;
  }
}

.ant-tooltip-inner {
  @apply p-2.5 bg-black-op-90 rounded-2lg;
}

.ant-tooltip-arrow-content {
  @apply bg-black-op-70;
}
