.ant-upload.ant-upload-drag {
  @apply border-2 border-dashed border-charcoal h-auto;
}

.ant-upload.ant-upload-drag .ant-upload,
.ant-upload {
  @apply p-0 h-auto;
}

.ant-upload.ant-upload-drag {
  .ant-upload-drag-container {
    @apply block;
  }
}

.venue-image {
  width: 100%;
  height: auto;
  aspect-ratio: 1.77;
}

.venue-image-card {
  aspect-ratio: 4/3;
}
