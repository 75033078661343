.ant-modal {
  @apply rounded-2.5xl overflow-hidden shadow-m-10 bg-primary-white pb-0;
}

.ant-modal-header {
  @apply p-0 bg-transparent rounded-none border-none;
}

.ant-modal-content {
  @apply shadow-none;
}

.ant-modal-mask {
  @apply bg-black-op-20;
}

.ant-modal-body {
  @apply p-0;
}

.ant-modal-footer {
  @apply border-none;
}
