.ant-select.select {
  @apply h-10 text-m;

  &:not(.ant-select-customize-input) .ant-select-selector {
    @apply h-full bg-ice-150 text-base text-gray-600 px-4 py-2;
    font-size: inherit;

    .ant-select-selection-search-input {
      @apply h-full;
      font-size: inherit;
    }

    .ant-select-selection-item,
    .ant-select-selection-placeholder {
      font-size: inherit;
      line-height: inherit;
    }
  }

  &.default:not(.ant-select-customize-input) .ant-select-selector {
    @apply rounded-md;
  }

  &.curved:not(.ant-select-customize-input) .ant-select-selector {
    @apply rounded-3xl;
  }

  .ant-select-arrow {
    @apply top-2/4 w-auto h-auto text-primary-black;
    margin-top: -8px;
  }
}
