.toggle-button-wrapper {
  @apply inline-flex items-center justify-center rounded-lg border-2 border-transparent;
  @apply text-m text-primary-black cursor-pointer p-1 h-8 min-w-8;

  .toggle-button {
    @apply hidden;
  }

  &.type-dark {
    @apply hover:bg-black-op-70 hover:text-primary-white;
  }

  &.type-light {
    @apply hover:bg-white-op-90;
  }

  &.type-transparent-dark {
    @apply hover:bg-black-op-10;
  }

  &.type-transparent-light {
    @apply text-primary-white hover:bg-white-op-30;
  }

  &.type-dark,
  &.type-light {
    @apply bg-ice-100;
  }

  &.ant-switch-checked {
    &.type-dark {
      @apply text-primary-white bg-primary-black;
    }

    &.type-light {
      @apply bg-primary-white;
    }

    &.type-transparent-dark {
      @apply border-black-op-10 hover:bg-transparent;
    }

    &.type-transparent-light {
      @apply border-white-op-30 hover:bg-transparent;
    }
  }

  &&-disabled {
    &,
    &.type-dark {
      @apply text-black-op-50 pointer-events-none cursor-auto;
    }

    &.type-dark,
    &.type-light {
      @apply bg-grey-10;
    }
  }
}
