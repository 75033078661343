.ant-popover-inner-content {
  @apply p-0;
}

.ant-popover-inner {
  @apply rounded-lg p-2.5;
}

.popover-elements-dropdown {
  .item {
    @apply flex items-center overflow-hidden text-m py-2;

    svg {
      @apply mr-2.5 -ml-5;
      transition: margin-left 0.4s;
    }

    &:hover {
      @apply font-bold text-black;

      svg {
        @apply ml-0;
      }
    }
  }
}

.popover-dropdown-menu {
  .item {
    @apply flex items-center overflow-hidden text-m py-2;
    @apply border-b border-ice-150;

    &:first-child {
      @apply cursor-pointer;
    }

    &:last-child {
      @apply border-b-0;
    }

    &:hover {
      @apply text-black;
      text-shadow: 0 0 1px currentColor;

      svg {
        @apply ml-0;
      }
    }
  }
}

.popover-dropdown-menu-with-icon {
  @apply cursor-pointer w-8 h-8 border-2 border-transparent rounded-lg mx-3;
}
