.calendar-wrapper {
  height: 485px;
  width: 520px;
  @apply rounded-lg;
  @apply shadow-lg;
  @apply overflow-hidden;
  @apply bg-primary-white;

  .shortcuts {
    width: 10.625rem;
    @apply text-sm;
    @apply text-black;
    @apply py-2;
    @apply flex;
    @apply flex-col;
    @apply justify-between;
    @apply items-start;
    @apply border-r;
    @apply border-charcoal;
  }

  .datepicker-wrapper {
    @apply flex-1;
    @apply flex;
    @apply h-full;
    @apply relative;
    @apply overflow-scroll;
  }

  .react-datepicker__navigation {
    @apply sticky;
  }
  .react-datepicker__navigation--next {
    @apply float-right;
  }
  .react-datepicker__navigation--previous {
    @apply float-left;
  }

  .react-datepicker {
    @apply border-none;
    @apply rounded-lg;

    .react-datepicker__month-container {
      width: 100%;
      .react-datepicker__day-names {
        @apply text-sm;

        .react-datepicker__day-name {
          @apply text-gray-400;
        }
      }

      .react-datepicker__header {
        @apply sticky;
        @apply top-0;
        @apply -mt-5;
        @apply bg-primary-white;
        @apply border-gray-200;
      }
      &:last-of-type {
        .react-datepicker__day-names {
          display: none;
        }
        .react-datepicker__header {
          @apply p-0;
          @apply m-0;
          @apply text-base;
          @apply bg-primary-white;
          @apply border-none;
        }
      }
    }

    .react-datepicker__day-name {
      @apply m-0 w-10;
    }
    .react-datepicker__day {
      @apply text-base w-10;
      @apply m-0;
      @apply my-1;
      @apply rounded-none;
      line-height: 2.5rem;

      &:hover {
        @apply bg-green-20;
      }
      &:focus {
        @apply outline-none;
        @apply shadow-sm;
      }
    }

    .react-datepicker__day--in-range {
      @apply text-black;
      @apply bg-green-20;
      @apply bg-opacity-50;
      @apply rounded-none;
    }

    .react-datepicker__day--in-selecting-range {
      @apply text-black;
      @apply bg-green-20;
      @apply bg-opacity-50;
    }

    .react-datepicker__day--selecting-range-start,
    .react-datepicker__day--range-start {
      @apply from-primary-white;
      @apply via-primary-white;
      --gradient-to-color: #e6ffe6;
      @apply bg-opacity-50;
      @apply bg-gradient-to-r;
      .day-inner {
        @apply shadow-lg;
        @apply border-2;
        @apply rounded-full;
        @apply inline-block;
        @apply border-primary-white;
        @apply bg-green-20 w-10;
        line-height: 2.25rem;
      }
    }
    .react-datepicker__day--range-end {
      @apply from-primary-white;
      --gradient-to-color: #e6ffe6;
      @apply bg-opacity-50;
      @apply bg-gradient-to-l;
      &:focus {
        @apply outline-none;
      }
      .day-inner {
        @apply shadow-lg;
        @apply inline-block;
        @apply border-2;
        @apply rounded-full;
        @apply bg-green-20;
        @apply border-black w-10;
        line-height: 2.25rem;
      }
    }

    .react-datepicker__day--range-start.react-datepicker__day--range-end {
      @apply bg-none;
      @apply bg-transparent;
    }
  }
}
