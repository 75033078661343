.ant-checkbox-wrapper {
  @apply inline-flex cursor-pointer;

  .ant-checkbox {
    @apply hidden;
  }

  .indeterminate {
    @apply fill-yellow-10;
  }

  &&-disabled {
    @apply pointer-events-none text-black-op-40;

    .square {
      @apply fill-ice-150;
    }
  }

  .check-mark {
    @apply absolute top-1/2 left-1/2 transform  -translate-x-1/2 -translate-y-1/2 hidden;
  }

  .ant-checkbox-checked + span {
    .square {
      @apply fill-green-20;
    }

    .check-mark {
      @apply block;
    }
  }
}
