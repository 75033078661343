.ant-input-number {
  @apply inline-block transition-all duration-300;
  @apply z-0 rounded-md bg-ice-150 text-base text-gray-600 px-4 py-2 mt-0;
  @apply leading-6 relative w-full m-0 p-0;

  .ant-input-number-handler-wrap {
    @apply absolute top-0 right-0 h-full bg-ice-150 rounded-tr-md rounded-br-md;
    @apply opacity-0 text-sm flex justify-between items-center flex-col w-7;
    transition: opacity 0.24s linear 0.1s;

    .ant-input-number-handler {
      @apply cursor-pointer text-gray-400 font-bold overflow-hidden;
      @apply flex items-center justify-center relative border-l border-gray-400;
      @apply w-full h-1/2;

      &.ant-input-number-handler-up {
        @apply rounded-tr-md;
      }

      &.ant-input-number-handler-down {
        @apply top-0 rounded-br-md border-t border-gray-400;
      }
    }
  }

  .ant-input-number-input-wrap {
    @apply z-0 rounded-md bg-ice-150 text-base text-gray-600 px-4 py-2 mt-0;

    .ant-input-number-input {
      @apply w-full text-left bg-transparent border-0 outline-none;
    }
  }
}

.ant-input-number:hover {
  @apply border-blue-300 border-r;
}

.ant-input-number:focus,
.ant-input-number-focused {
  @apply border-blue-300 border-r;
  box-shadow: 0 0 0 2px rgb(24 144 255 / 20%);
}

.ant-input-number:hover .ant-input-number-handler-wrap,
.ant-input-number-focused .ant-input-number-handler-wrap {
  opacity: 1;
}
