.dietary-modal {
  .checkbox-text {
    @apply ml-2.5 text-m;
  }

  .checkbox-wrapper {
    @apply flex items-center border-b border-black-op-10 pb-2 mb-2;
  }

  .checkbox-wrapper:last-child {
    @apply border-b-0;
  }

  .ant-checkbox-checked + span .checkbox-text {
    @apply font-bold;
  }
}
