@media print {
  @page {
    size: 210mm 297mm;
    /* 794px x 1122px */
  }

  /* Disables header and footer in Safari print window */
  header,
  footer {
    display: none;
  }

  .print-menu-page-a4 {
    width: 210mm;
    height: 297mm;
    padding: 25mm 33mm 17mm 33mm;
    box-shadow: none;
    margin-bottom: 0px;
  }
}

@media screen {
  .print-menu-page-a4 {
    width: 210mm;
    height: 297mm;
    margin: 0 auto;
    padding: 25mm 33mm 17mm 33mm;
    box-shadow: 0px 0px 12.528px rgba(0, 0, 0, 0.1);
    margin-bottom: 16px;
    background-color: white;
  }
}

.print-dashed-line {
  flex-grow: 1;
  margin-left: 8px;
  margin-right: 8px;
  border-bottom: 1px dashed rgba(0, 0, 0, 0.2);
  margin-bottom: 0.25rem;
}

.print-menu .font-bold {
  font-weight: 700;
}

.print-menu-title {
  font-weight: 700;
  font-size: 30px;
  line-height: 1.25;
}

.print-category-title {
  font-weight: 700;
  font-size: 26px;
  line-height: 1.25;
}

.print-subcategory-title {
  font-weight: 700;
  font-size: 22px;
  line-height: 1.25;
}

.print-product-title-price {
  font-weight: 700;
  font-size: 18px;
  line-height: 1.25;
}

.print-product-description {
  margin-top: 0.25rem;
}

.print-menu-product-container {
  page-break-inside: avoid;
}

.print-allergens {
  font-size: 13px;
  line-height: 1.25;
  margin-top: 0.25rem;
}

.print-menu-sidebar {
  overflow: auto;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  background: white;
}
