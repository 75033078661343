@import "sidebar.css";
@import "calendar.css";
@import "toggle-button.css";
@import "drawer.css";
@import "tabs.css";
@import "accordion.css";
@import "tooltip.css";
@import "popover.css";
@import "progress.css";
@import "message.css";
@import "modal.css";
@import "upload.css";
@import "dietary-modal.css";
@import "form/index.css";
@import "create-venue-drawer.css";
@import "avatar-dropwdown.css";
@import "print-menu.css";

@import "~react-datepicker/dist/react-datepicker.css";

.__react_component_tooltip {
  opacity: 1 !important;
  &p-0 {
    @apply p-0;
  }
}
