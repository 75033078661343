.ant-btn {
  @apply inline-flex items-center justify-center rounded-lg border-2 border-transparent text-m text-primary-black font-bold;
  @apply hover:shadow-m hover:border-primary-white focus:outline-none;
  transition: background-color 0.2s ease-out, box-shadow 0.2s ease-out;

  &.size- {
    &small {
      @apply p-1.5 h-7.5;
    }

    &medium {
      @apply px-2.5 py-1.5 h-9;
    }

    &large {
      @apply px-4.5 py-2.5 h-11;
    }
  }

  &.type-outline.size- {
    &small {
      @apply px-1.5 py-1 h-7.5;
    }

    &medium {
      @apply px-3 py-2 h-10;
    }

    &large {
      @apply px-4.5 py-3 h-12;
    }
  }

  &.type-icon-only {
    @apply rounded-lg;

    &.size- {
      &extra-small {
        @apply p-1 h-8 min-w-8;
      }

      &small {
        @apply p-2 h-10.5 min-w-10.5;
      }

      &medium {
        @apply p-3 h-14.5 min-w-14.5;
      }

      &large {
        @apply p-3.5 h-18 min-w-18;
      }
    }
  }

  &.color- {
    &green {
      @apply bg-green-20;
      @apply hover:bg-green-20;
    }

    &orchid {
      @apply bg-orchid-10;
      @apply hover:bg-orchid-10;
    }

    &red {
      @apply bg-red-10;
      @apply hover:bg-red-10;
    }

    &yellow {
      @apply bg-red-30;
      @apply hover:bg-red-30;
    }

    &light {
      @apply bg-ice-100;
      @apply hover:bg-ice-100;
    }

    &black {
      @apply bg-primary-black text-primary-white;
      @apply hover:bg-primary-black;
    }

    &white {
      @apply bg-primary-white;
      @apply hover:bg-primary-white;
    }
  }

  &.btn-disabled {
    @apply bg-grey-10;
  }

  &.type-outline {
    &.color- {
      &green {
        @apply border-green-20;
      }

      &orchid {
        @apply border-orchid-10;
      }

      &red {
        @apply border-red-10;
      }

      &yellow {
        @apply border-red-30;
      }

      &light {
        @apply border-ice-100;
      }

      &black {
        @apply border-primary-black text-black hover:text-primary-white;
      }

      &white {
        @apply border-primary-white text-primary-white;
        @apply hover:text-primary-black;
      }
    }

    @apply bg-transparent hover:border-primary-white;

    &.btn-disabled {
      @apply border-grey-10;
    }
  }

  &.btn-disabled {
    @apply text-black-op-50 border-grey-10 shadow-none pointer-events-none cursor-auto;
  }

  &.btn-raised {
    @apply shadow-m border-primary-white;

    &:hover {
      @apply shadow-raised;
    }
  }
}
