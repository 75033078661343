aside {
  .sidebar {
    .icon {
      @apply w-8;
      @apply h-8;
      @apply bg-ice-100;
      @apply text-black;
      &:hover {
        @apply bg-black;
        @apply text-primary-white;
      }
    }

    .link {
      @apply w-auto;
      @apply flex;
      @apply items-center;
      @apply my-4;

      &:hover {
        .icon {
          @apply bg-black;
          @apply text-primary-white;
        }
      }
      &.active {
        .icon {
          @apply bg-black;
          @apply text-primary-white;
        }
      }
    }
  }

  .mobile-avatar-drawer {
    transform: translateY(172px);
    transition: transform 0.3s ease-in-out;

    &.mobile-avatar-drawer.show {
      transform: translateY(0);
    }
    .user-details {
      @apply bg-ice-50 -mt-4 -mx-4 p-4 cursor-pointer;
    }
  }
}

.shown-sidebar {
  @apply overflow-hidden;
}

@screen md {
  .shown-sidebar {
    @apply overflow-auto;
  }
}
