.custom-progress {
  .ant-progress {
    line-height: 0;
  }

  .ant-progress-inner {
    @apply bg-primary-white p-px  rounded mt-1.5;
  }

  .ant-progress-bg {
    @apply bg-primary-black rounded-sm;
    @apply h-1 !important;
  }
}
