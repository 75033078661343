.ant-collapse {
  @apply border-0;
  .ant-collapse-header {
    @apply bg-ice-100 py-1 pr-2 rounded-3xl my-2 !important;
  }

  .ant-collapse-ghost {
    .ant-collapse-header {
      @apply bg-transparent !important;
    }

    .ant-collapse-content-box {
      @apply p-0 !important;
    }
  }

  .ant-collapse-content-box {
    @apply p-0;
  }

  .ant-collapse-content,
  .ant-collapse-item {
    @apply border-0;
    @apply bg-transparent !important;
  }

  .ant-collapse-arrow {
    @apply p-0 top-0 bottom-0 !important;
    @apply left-1 !important;
  }

  &.ant-collapse-icon-position-right {
    .ant-collapse-header {
      @apply pl-2 !important;
    }

    .ant-collapse-arrow {
      @apply right-1 !important;
      @apply left-auto !important;
    }
  }

  &.ant-collapse-icon-position-left {
    .ant-collapse-header {
      @apply pl-6 !important;
    }

    .ant-collapse-arrow {
      @apply top-2 !important;
      @apply left-1 !important;
      @apply right-auto !important;
    }
  }
}
